/* Logo */
import isotipo from './logo/isotipo.svg'
import logoLarge from './logo/logo-large.svg'
import logoSmall from './logo/logo-small.svg'
import makingItBetter from './logo/making-it-better.svg'
import globalLynxBlog from './logo/global-lynx-blog.svg'
import globalLynxLetters from './logo/global-lynx.svg'
import globalLynxBlueLetters from './logo/global-lynx-blue.svg'

/* FontAwesome */
import anglesLeft from './fontawesome/angles-left.svg'
import anglesRight from './fontawesome/angles-right.svg'
import angleDown from './fontawesome/angle-down.svg'
import arrowLeft from './fontawesome/arrow-left.svg'
import arrowRight from './fontawesome/arrow-right.svg'
import arrowUpRight from './fontawesome/arrow-up-right.svg'
import briefcaseSolid from './fontawesome/briefcase-solid.svg'
import briefcase from './fontawesome/briefcase.svg'
import calendar from './fontawesome/calendar.svg'
import caretDown from './fontawesome/caret-down.svg'
import caretLeft from './fontawesome/caret-left.svg'
import caretRight from './fontawesome/caret-right.svg'
import caretUp from './fontawesome/caret-up.svg'
import certificate from './fontawesome/certificate.svg'
import certification from './fontawesome/certification.svg'
import chalkboardUser from './fontawesome/chalkboard-user.svg'
import check from './fontawesome/check.svg'
import checkBold from './fontawesome/check-bold.svg'
import chevronDown from './fontawesome/chevron-down.svg'
import chevronRight from './fontawesome/chevron-right.svg'
import chevronUp from './fontawesome/chevron-up.svg'
import chevronLeft from './fontawesome/chevron-left.svg'
import circle from './fontawesome/circle.svg'
import circleCheck from './fontawesome/circle-check.svg'
import circleUser from './fontawesome/circle-user.svg'
import clockRegular from './fontawesome/clock-regular.svg'
import clockRotateLeft from './fontawesome/clock-rotate-left.svg'
import close from './fontawesome/close.svg'
import diagramProject from './fontawesome/diagram-project.svg'
import download from './fontawesome/download.svg'
import drag from './fontawesome/drag.svg'
import email from './fontawesome/email.svg'
import eyeSlash from './fontawesome/eye-slash.svg'
import eye from './fontawesome/eye.svg'
import facebook from './fontawesome/facebook.svg'
import fecha from './fontawesome/fecha.svg'
import folder from './fontawesome/folder.svg'
import info from './fontawesome/info.svg'
import instagram from './fontawesome/instagram.svg'
import linkedin from './fontawesome/linkedin.svg'
import locationSolid from './fontawesome/location.svg'
import locationDot from './fontawesome/location-dot.svg'
import logout from './fontawesome/logout.svg'
import menu from './fontawesome/menu.svg'
import pencil from './fontawesome/pencil.svg'
import plus from './fontawesome/plus.svg'
import phoneSolid from './fontawesome/phone-solid.svg'
import search from './fontawesome/search.svg'
import speaker from './fontawesome/speaker.svg'
import star from './fontawesome/star.svg'
import tag from './fontawesome/tag.svg'
import twitter from './fontawesome/twitter.svg'
import user from './fontawesome/user.svg'
import youtube from './fontawesome/youtube.svg'
import whatsapp from './fontawesome/whatsapp.svg'
import trash from './fontawesome/trash.svg'
import table from './personalized/table.svg'

export {
  /* Logo */
  isotipo,
  logoLarge,
  logoSmall,
  makingItBetter,
  globalLynxBlog,
  globalLynxLetters,
  globalLynxBlueLetters,
  /* FontAwesome */
  anglesLeft,
  anglesRight,
  angleDown,
  arrowLeft,
  arrowRight,
  arrowUpRight,
  briefcaseSolid,
  briefcase,
  calendar,
  caretDown,
  caretLeft,
  caretRight,
  caretUp,
  certificate,
  certification,
  chalkboardUser,
  check,
  checkBold,
  chevronDown,
  chevronRight,
  chevronUp,
  chevronLeft,
  circle,
  circleCheck,
  circleUser,
  clockRegular,
  clockRotateLeft,
  close,
  diagramProject,
  download,
  drag,
  email,
  eyeSlash,
  eye,
  facebook,
  fecha,
  folder,
  info,
  instagram,
  linkedin,
  locationSolid,
  locationDot,
  logout,
  menu,
  pencil,
  plus,
  phoneSolid,
  search,
  speaker,
  star,
  tag,
  trash,
  twitter,
  user,
  youtube,
  whatsapp,

  /* Personalized */
  table
}
